/*
 * @Descripttion: 
 * @version: 
 * @Author: Hou Jiechao
 * @Date: 2021-05-31 16:30:44
 * @LastEditors: Hou Jiechao
 * @LastEditTime: 2022-02-21 16:29:03
 * @FilePath: \工作\storehouse\src\utils\api.js
 */
import http from './http'
let api = process.env.NODE_ENV == 'development' ? 'https://api.singran.vip' : 'https://api.singran.vip'
const factory_api = 'https://api.singran.vip/api-web/api'
// const factory_api = '/api'
export default {
  hotelassetsquery(params) {
    return http.post(`${api}/hotel/assetsquery`, params)
  },
  hotelassetdate(params) {
    return http.post(`${api}/hotel/assetdate`, params)
  },
  hotelassetsdetail(params) {
    return http.post(`${api}/hotel/assetsdetail`, params)
  },
  hoteldevice(params) {
    return http.post(`${api}/hotel/device`, params)
  },
  hotelassetsrfiddetail(params) {
    return http.post(`${api}/hotel/assetsrfiddetail`, params)
  },
  videohttps(params) {
    return http.get(`https://device.singran.vip/api/fetchVideoInfo`, params)
  },
  apptonelist(params) {
    return http.post(`${api}/app/tonelist`, params)
  },
  rfidcloseservice(params) {
    return http.post(`${api}/rfid/closeservice`, params)
  },
  hotelfactory(params) {
    return http.post(`${api}/hotel/factory`, params)
  },
  hotelleaser(params) {
    return http.post(`${api}/hotel/leaser`, params)
  },
  hotelaasetinandout(params) {
    return http.post(`${api}/hotel/aasetinandout`, params)
  },
  factorydevice(params) {
    return http.post(`${api}/factory/device`, params)
  },
  factoryindicatorsdata(params) {
    return http.post(`${api}/factory/indicatorsdata`, params)
  },
  factorywashtracedata(params) {
    return http.post(`${api}/factory/washtracedata`, params)
  },
  factoryindicator(params) {
    return http.get(`/api/api/factory/indicator`, params)
  },
  factorytracedate(params) {
    return http.get(`/api/api/factory/tracedate`, params)
  },
  factoryrfid(params) {
    return http.get(`/api/api/factory/rfid`, params)
  },
  factorydetaildate(params) {
    return http.get(`/api/api/factory/detaildate`, params)
  },
  // 新
  factorydevicewashsummary(params) {
    return http.post(`${api}/factory/devicewashsummary`, params)
  },
  factorydevicewashindicator(params) {
    return http.post(`${api}/factory/devicewashindicator`, params)
  },
  factorydevicewashcost(params) {
    return http.post(`${api}/factory/devicewashcost`, params)
  },
  factorydesk(params) {
    return http.post(`${api}/desk/fetchdeskdetail`, params)
  },
  bandbfactory(params) {
    return http.post(`${api}/bandb/factory`, params)
  },
  deskservice(params) {
    return http.post(`${api}/desk/service`, params)
  },
  doorservice(params) {
    return http.post(`${api}/door/service`, params)
  },
  servicecontrol(params) {
    return http.post(`${api}/service/control`, params)
  },
  deskcommitorder(params) {
    return http.post(`${api}/desk/commitorder`, params)
  },
  deviceqrauth(params) {
    return http.post(`${api}/device/qrauth`, params)
  },
  washorderdetails(params) {
    return http.post(`${api}/wash/orderdetails`, params)
  },
  deviceget(params) {
    return http.post(`${api}/device/get`, params)
  },
  washorderaction(params) {
    return http.post(`${api}/wash/orderaction`, params)
  },
  createownlinenwashdetail(params) {
    return http.post(`${api}/hotel/createownlinenwashdetail`, params)
  },
  createownlinenwash(params) {
    return http.post(`${api}/hotel/createownlinenwash`, params)
  },
  createownlinenfeed(params) {
    return http.post(`${api}/hotel/createownlinenfeed`, params)
  },
  rfidbucaotype(params) {
    return http.post(`${api}/rfid/rfidbucaotype`, params)
  },
  devicefetch(params) {
    return http.post(`${api}/device/fetch`, params)
  },
  deskfetchreadingservice(params) {
    return http.post(`${api}/desk/fetchreadingservice`, params)
  },
  staffgetstaffinfo(params) {
    return http.post(`${api}/staff/getstaffinfo`, params)
  },
  washdifforderwash(params) {
    return http.post(`${api}/wash/difforderwash`, params)
  },
  packorderpack(params) {
    return http.post(`${api}/pack/orderpack`, params)
  },
  gethotelfromservice(params) {
    return http.post(`${api}/desk/gethotelfromservice`, params)
  },
  feedorderdetails(params) {
    return http.post(`${api}/feed/orderdetails`, params)
  },
  feeddifforderfeed(params) {
    return http.post(`${api}/feed/difforderfeed`, params)
  },
  feedorderaction(params) {
    return http.post(`${api}/feed/orderaction`, params)
  },
  washdetectorderwash(params) {
    return http.post(`${api}/wash/detectorderwash`, params)
  },
  // factoryleaseorder(params) {
  //   return http.post(`${api}/factory/factoryleaseorder`, params)
  // },
  // hotelowncreateorder(params) {
  //   return http.post(`${api}/hotel/owncreateorder`, params)
  // },
  hotelcreateorder(params) {
    return http.post(`${api}/hotel/hotelcreateorder`, params)
  },
  factorycreateorder(params) {
    return http.post(`${api}/factory/factorycreateorder`, params)
  },
  leaserfindcustomer(params) {
    return http.post(`${api}/leaser/findcustomer`, params)
  },
  feedboxcreateorder(params) {
    return http.post(`${api}/feed/boxcreateorder`, params)
  },
  resetdevice(params) {
    return http.post(`${api}/device/rfidreadercontrol`, params)
  },
  hotellargescreen(params) {
    return http.post(`${api}/hotel/largescreen`, params)
  },
  washrelationwash(params) {
    return http.post(`${api}/wash/relationwash`, params)
  },
  washverifyrelationwash(params) {
    return http.post(`${api}/wash/verifyrelationwash`, params)
  },
  readerstatus(params) {
    return http.post(`${api}/reader/status`, params)
  },
  staffrecvstaffinfo(params) {
    return http.post(`${api}/staff/recvstaffinfo`, params)
  },
  leaserrelationleaserorder(params) {
    return http.post(`${api}/leaser/relationleaserorder`, params)
  },
  hotelroomrelation(params) {
    return http.post(`${api}/picking/hotelroomrelation`, params)
  },
  createorderpicking(params) {
    return http.post(`${api}/picking/createorderpicking`, params)
  },
  pickingrefundinfo(params) {
    return http.post(`${api}/picking/pickingrefundinfo`, params)
  },
  createpickingrefund(params) {
    return http.post(`${api}/picking/createpickingrefund`, params)
  },
  /* 工厂手动打包相关 */
  //获取工厂信息
  getFactoryInfo(params) {
    return http.get(`${factory_api}/device/${params}/detail`)
  },
  //获取工厂的客户信息
  getHotelInfo(params) {
    return http.get(`${factory_api}/factory/${params}/customers`)
  },
  //获取工厂最近打包过的客户信息
  getrecentPackInfo(params) {
    return http.get(`${factory_api}/factory/${params}/recentPackCustomers`)
  },
  //获取酒店布草类型
  getHotelLinenType(params) {
    return http.get(`${factory_api}/hotel/${params}/bucao/category`)
  },
  //获取酒店打包规格
  getHotelLinenSize(code, category) {
    return http.get(`${factory_api}/hotel/${code}/bucao/category/${category}/scale`)
  },
  //布草无芯片打包
  uploadPack(params) {
    return http.post(`${factory_api}/pack/addWithNoneRFID`, params, 'to')
  },

  //工厂模拟数据接口

  mockFactorydevicewashcost(params) {
    return http.post(`${api}/mock/devicewashcost`, params)
  },

  mockFactorydevicewashindicator(params) {
    return http.post(`${api}/mock/devicewashindicator`, params)
  },

  mockFactoryindicatorsdata(params) {
    return http.post(`${api}/mock/indicatorsdata`, params)
  },

  mockFactorydevicewashsummary(params) {
    return http.post(`${api}/mock/devicewashsummary`, params)
  },
}